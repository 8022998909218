import React from 'react'
import Glitch from "@donnieashok/react-textglitch"
import ReactTyped from "react-typed"
import Nav from '../parts/nav'
import Footer from '../parts/footer'

export default function Home() {
  return (
    <div className="w-screen h-screen p-8 font-mono leading-relaxed bg-dark">
      <div className="flex flex-col w-full h-full bg-dim">
        <Nav />
        <div className="h-full overflow-hidden">
          <div className="flex h-full">
            <div className="mx-auto my-auto" >
              <Glitch>
                <h1 className="text-2xl uppercase text-light sm:text-4xl lg:text-6xl" >I'm Donnie Ashok</h1>
              </Glitch>
              <div className="w-56 h-16 text-sm text-light md:text-base md:w-auto">
                <ReactTyped
                  strings={
                    [
                      'Senior DevOps',
                      'Senior SRE',
                      'Based out of Berlin',
                      'Experienced in Golang',
                      'Experienced in Kubernetes',
                      'Experienced with AWS',
                      'Senior DevOps',
                    ]
                  }
                  typeSpeed={40}
                  backSpeed={15}
                  smartBackspace
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
