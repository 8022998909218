import React from "react";

export default function Resume() {
  return (
    <div className="p-8 font-sans text-sm leading-relaxed text-gray-700">
      <div className="mx-auto max-w-4xl">
        <header className="my-4">
          <h1 className="mb-2 text-3xl font-bold">Donnie Ashok</h1>
          <p className="text-xl">Senior DevOps Engineer</p>
          <p className="space-x-2">
            <span>Email: jobs@donnie.in</span>
            <span>|</span>
            <span>
              Website:{" "}
              <a
                href="https://donnie.in"
                className="text-blue-600 transition-colors duration-300 hover:text-blue-800"
              >
                https://donnie.in
              </a>
            </span>
            <span>|</span>
            <span>Location: Remote, Germany</span>
          </p>
        </header>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Summary</h2>
          <p>
            Senior DevOps Engineer with over a decade of experience in the
            Software Development Life Cycle, utilizing the latest technologies.
            Skilled in CI/CD automation, infrastructure management, and GitOps
            principles.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Skills</h2>
          <ul className="list-none  leading-tight">
            <li>
              <span className="font-semibold">Cloud Platforms:</span> AWS, GCP,
              Digital Ocean, Linode, Cloudflare
            </li>
            <li>
              <span className="font-semibold">
                Containerization & Orchestration:
              </span>{" "}
              Kubernetes, AWS ECS, AWS Fargate, Argo CD
            </li>
            <li>
              <span className="font-semibold">Infrastructure as Code:</span>{" "}
              Terragrunt, Terraform, Ansible, Pulumi, Crossplane
            </li>
            <li>
              <span className="font-semibold">CI/CD and automations:</span>{" "}
              Github Actions, Argo Workflows, Jenkins
            </li>
            <li>
              <span className="font-semibold">Backend:</span> Golang,
              Typescript, Node.js, PHP, Rust
            </li>
            <li>
              <span className="font-semibold">Frontend:</span> React, Angular,
              Next.js
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Work Experience</h2>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Senior DevOps Engineer - Moonfare GmbH
            </h3>
            <time className="italic">July 2024 - Present</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Created Private Hosted Zones with TLS Termination for Internal
                resources
              </li>
              <li>
                Wrote Golang controllers to automate unused resource detection
                and deletion
              </li>
              <li>
                Deployed Cross Account Cross Cluster Argo CD with OIDC IAM
                authorisation
              </li>
              <li>
                Automated security scans in all clusters using Wiz for container
                vulnerability assessment
              </li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              DevOps Engineer - Moonfare GmbH
            </h3>
            <time className="italic">July 2022 - June 2024</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Recreated production network infrastructure for ISO 27001
                Compliance
              </li>
              <li>
                Implemented monitoring using New Relic for infrastructure and
                application metrics
              </li>
              <li>
                Developed dynamic scaling and spot node provisioning using
                Karpenter
              </li>
              <li>Created pipelines for CD using Argo Workflows and Argo CD</li>
              <li>
                Implemented dynamic preview environments to decrease Mean Time
                To Recovery and increase deployment frequency
              </li>
              <li>
                Reduced computation and networking costs by up to 60%
                year-over-year for 2023/2024
              </li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Software Development Engineer - Moonfare GmbH
            </h3>
            <time className="italic">July 2021 - June 2022</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Built and maintained customer-facing web application and backend
                services using Typescript with React and Node.js
              </li>
              <li>
                Implemented in-app PDF parsing which was run at scale for
                thousands of PDFs per minute
              </li>
              <li>
                Rewrote Dockerfile of a few containers to speed up build and
                deploy phase
              </li>
              <li>Wrote healthchecks for apps with critical workflows</li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Full Stack Developer - Medigo GmbH
            </h3>
            <time className="italic">December 2018 - June 2021</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>Implemented frontend designs, backend services, and APIs</li>
              <li>Managed CI/CD lifecycle with Jenkins and AWS ECS</li>
              <li>Worked on security hardening on Cloudflare and AWS VPC</li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">
              Technology Manager - Intelligent Legal Risk Management Solutions
            </h3>
            <time className="italic">May 2017 - November 2018</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Created the first backend of LawSikho.com with online
                application process and payment gateway
              </li>
              <li>
                Built and managed the first engineering team of four developers
              </li>
              <li>
                Developed real-time internal dashboard with Angular 7 and PHP
              </li>
              <li>
                Deployed the iPleaders Blog with High Availability, it continues
                to receive over 1.5m visits per month
              </li>
            </ul>
          </article>

          <article className="mb-4">
            <h3 className="text-lg font-semibold">Founder - Legalify</h3>
            <time className="italic">May 2011 - April 2017</time>
            <ul className="mt-2 list-disc pl-5  leading-tight">
              <li>
                Specialized in comprehensive and regulatory compliant web
                solutions for the Indian Legal industry
              </li>
              <li>
                Deployed self-hosted secure and private email infrastructure for
                law firms
              </li>
              <li>
                Focused on tailored and scalable WordPress hosting and
                development
              </li>
            </ul>
          </article>
        </section>

        <section className="mb-4">
          <h2 className="mb-2 text-xl font-semibold">Education</h2>
          <h3 className="text-lg font-semibold">
            B.A. LL.B - Gujarat National Law University
          </h3>
          <time className="italic">2012 - 2017</time>
          <p>Qualified to practice Law in India</p>
        </section>

        <section>
          <h2 className="mb-2 text-xl font-semibold">Languages</h2>
          <p>English, German (B2), Hindi, Bengali</p>
        </section>
      </div>
    </div>
  );
}
