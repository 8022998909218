import React from 'react'
import ReactTooltip from "react-tooltip"
import Footer from '../parts/footer'
import Nav from '../parts/nav'

export default function Contact() {
  return (
    <div className="w-screen h-screen p-8 font-mono leading-relaxed bg-dark">
      <div className="flex flex-col w-full h-full bg-dim">
        <Nav />
        <div className="h-full overflow-hidden">
          <div className="flex h-full">
            <div className="mx-auto my-auto">
              <div className="px-8 border-2 border-white text-light">
                <span className="cursor-pointer" data-tip="Copied!">Email</span>
              </div>
              <div className="px-8 border-2 border-white text-light">
                <a href="https://meet.donnie.in/30min" target="_blank" rel="noopener noreferrer">Meet</a>
              </div>
              <div className="px-8 border-2 border-white text-light">
                <a href="https://github.com/Donnie" target="_blank" rel="noopener noreferrer">Github</a>
              </div>
              <div className="px-8 border-2 border-white text-light">
                <a href="https://www.linkedin.com/in/donnieask/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </div>
              <div className="px-8 border-2 border-white text-light">
                <a href="https://berlin.social/@donnie" target="_blank" rel="noopener noreferrer">Mastodon</a>
              </div>
              <div className="px-8 border-2 border-white text-light">
                <a href="https://t.me/DonnieAshok" target="_blank" rel="noopener noreferrer">Telegram</a>
              </div>
            </div>
            <ReactTooltip globalEventOff="mouseup" event="mousedown" delayHide={1000} afterShow={() => {navigator.clipboard.writeText('hello@donnie.in')}} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
