import React from "react";
import { Link } from "react-router-dom";
import Footer from "../parts/footer";
import Nav from "../parts/nav";

export default function Bio() {
  return (
    <div className="h-screen w-screen bg-dark p-8 font-mono leading-relaxed">
      <div className="flex h-full flex-col bg-dim">
        <Nav />
        <div className="flex h-screen flex-col overflow-auto text-xs text-gray-400 lg:mx-auto lg:flex-wrap lg:p-10 xxl:w-3/4">
          <div className="mb-4 px-6 text-lg uppercase text-light underline">
            Experience
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <div className="mb-3 table border border-orange px-1 text-orange">
              Jun 2022 - Present
            </div>
            <div className="mb-3 font-bold">
              Site Reliability Engineer -{" "}
              <a
                className="text-light"
                href="https://moonfare.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Moonfare
              </a>
            </div>
            <p>
              <ul className="list-disc">
                <li>
                  At Moonfare the CI/CD pipeline uses Argo Workflows and Argo CD
                  to deploy apps on EKS clusters.
                </li>
                <li>
                  My responsibilities include creation and maintenance of
                  critical automation flows, and triaging bugs during on-call.
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <div className="mb-3 table border border-orange px-1 text-orange">
              Jun 2021 - Jun 2022
            </div>
            <div className="mb-3 font-bold">
              Software Development Engineer -{" "}
              <a
                className="text-light"
                href="https://moonfare.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Moonfare
              </a>
            </div>
            <p>
              <ul className="list-disc">
                <li>
                  At Moonfare the stack is primarily Typescript based NodeJS and
                  React.
                </li>
                <li>
                  My responsibilities include development of new features and
                  fixing bugs across both frontend and backend.
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <div className="mb-3 table border border-orange px-1 text-orange">
              Dec 2018 - Jun 2021
            </div>
            <div className="mb-3 font-bold">
              Full Stack Developer -{" "}
              <a
                className="text-light"
                href="https://medigo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medigo
              </a>
            </div>
            <p>
              <ul className="list-disc">
                <li>
                  I belonged to a team of four where we built one-of-its-kind
                  Insurance Claims Management Portal.
                </li>
                <li>
                  I was responsible for creating new{" "}
                  <strong>UI components</strong> and implement critical{" "}
                  <strong>UX flows</strong> with{" "}
                  <strong>Tyepescript React</strong> while leveraging{" "}
                  <strong>Redux</strong> to hold huge amounts of modular data in
                  the state.
                </li>
                <li>
                  I had also created new <strong>data models</strong> and{" "}
                  <strong>API endpoints</strong> with <strong>Golang</strong>,
                  and been on On-Call for <strong>Dev Ops</strong>.We practiced
                  creating independent go-modules and used the asynchronicity of
                  Golang to our advantage.
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <div className="mb-3 table border border-orange px-1 text-orange">
              May 2017 - Nov 2018
            </div>
            <div className="mb-3 font-bold">
              Technology Manager -{" "}
              <a
                className="text-light"
                href="https://ipleaders.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                iPleaders
              </a>
            </div>
            <p>
              <ul className="list-disc">
                <li>
                  I was responsible for the creation and upkeep of the entire
                  online presence. I oversaw the building of{" "}
                  <strong>LawSikho</strong> which is currently the largest
                  online legal education portal of India.
                </li>
                <li>
                  The frontend was built with <strong>Angular 6</strong> with
                  server side rendering for perfect SEO. A custom{" "}
                  <strong>PHP</strong> codebase takes care of the backend
                  enabling the engagement and secure payments of thousands of
                  learners.
                </li>
                <li>
                  I also oversaw the high-availability of the iPleaders blog
                  running a custom <strong>Wordpress</strong> backend, which
                  receives over <strong>1.5 million visits</strong> a month.
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <div className="mb-3 table border border-orange px-1 text-orange">
              Jun 2011 - Apr 2017
            </div>
            <div className="mb-3 font-bold">
              Wordpress/PHP Developer - Freelance
            </div>
            <p>
              I founded the brand <strong>Legalify</strong> under which I helped
              lawyers in India to setup their online presence and gain
              popularity through content generation and SEO ranking, and at
              times protecting them from DDoS attacks as well. Many of them are
              currently wildly popular and continue to excel in their own
              domains.
              <ul className="list-disc text-light">
                <li>
                  <a
                    href="https://barhacker.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BarHacker
                  </a>
                </li>
                <li>
                  <a
                    href="https://indiacorplaw.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IndiaCorpLaw
                  </a>
                </li>
                <li>
                  <a
                    href="https://lawandotherthings.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LawAndOtherThings
                  </a>
                </li>
                <li>
                  <a
                    href="https://resolve.org.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Resolve Mediation
                  </a>
                </li>
                <li>
                  <a
                    href="https://blog.ipleaders.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iPleaders Blog
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 px-6 text-lg uppercase text-light underline">
            Education
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <div className="mb-3 table border border-orange px-1 text-orange">
              2012 - 2017
            </div>
            <div className="mb-3 font-bold">
              B.A.LL.B -{" "}
              <a
                className="text-light"
                href="https://en.wikipedia.org/wiki/Gujarat_National_Law_University"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gujarat National Law University
              </a>
            </div>
            <p>
              At University I was most interested in Public Law, International
              Law, Information Technology and Security Law and Competition Law.
              I graduated with Honours and qualified to practice law in India.
            </p>
          </div>
          <div className="mb-8 px-6 lg:w-1/2">
            <Link
              className="bg-dull mr-2 border-2 px-6 py-2 text-xs text-white"
              to="/about"
            >
              Go Back
            </Link>
            <Link
              className="bg-dull border-2 px-6 py-2 text-xs text-white"
              to="/resume"
            >
              See Resume
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
